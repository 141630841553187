<template>
  <div class="animated fadeIn">
    <el-row :gutter="20">
       <el-col :span="5">
         <el-select v-model="selectedOrganization" filterable placeholder="請選擇所屬單位" clearable>
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
       </el-col>
       <el-col :span="5">
         <el-upload
      v-loading="loading"
      ref="upload"
      action=""
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      :on-change="handleChange"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      :multiple="false"
      :limit="1"
      :file-list="fileList"
      :auto-upload="false">
  <el-button size="small" type="info" :disabled="!selectedOrganization">選擇Excel文件</el-button>
  <div slot="tip" class="el-upload__tip">限xlsx檔</div>
</el-upload>
       </el-col>
       <el-col :span="5">
         <el-button type="primary" @click="upload">上傳</el-button>
       </el-col>
       <el-col :span="5">
           <el-link href='/example.xlsx'>下載範例檔案<i class="el-icon-view el-icon--right"></i> </el-link>
       </el-col>
    </el-row>

<el-table
      :cell-style="cellStyle"
      :data="fileData"
      style="width: 100%" >
      <el-table-column
        prop="status"
        label="狀態"
        width="100">
      </el-table-column>
       <el-table-column
        prop="name"
        label="名稱"
        width="100">
      </el-table-column>
      <el-table-column
        prop="no"
        label="人員編號"
        width="100">
      </el-table-column>
      <el-table-column
        prop="class_name"
        label="班級名稱"
        width="100">
      </el-table-column>

     <el-table-column
        prop="start_date" :formatter="dateFormatter"
        label="生效日期"
        width="110">
      </el-table-column>
     <el-table-column
        prop="end_date"  :formatter="dateFormatter"
        label="失效日期"
        width="100">
      </el-table-column>
      <el-table-column
          label="所屬單位"
          prop="organization.name"
      >
      </el-table-column>
     <el-table-column
        prop="moonlight2_serial_number"
        label="月光機序號"
        width="200">
      </el-table-column>
          <el-table-column
        label="綁定澈見幸福App"
        width="140">
        <template slot-scope="scope">
          <el-switch
                  v-model="scope.row.is_link_luciditv_app" disabled
                  active-text="是"
                  inactive-text="否"></el-switch>
        </template>
      </el-table-column>
           <el-table-column
        prop="phone_area"
        label="註冊電話區碼"
        width="120">
      </el-table-column>
        <el-table-column
        prop="phone_number"
        label="註冊電話號碼"
        width="120">
      </el-table-column>
        <el-table-column
          label="群組"
      >
        <template slot-scope="scope">
        
              <span class="tag_group">
                <el-tag effect="dark" v-for="group in scope.row.groups" :key="group.id" :type="group.type">{{group.name}}</el-tag>
            </span>
            
        </template>
      </el-table-column>
        <el-table-column
        prop="memo"
        label="備註"
        width="100">
      </el-table-column>

</el-table>
  </div>
</template>

<script>
import API from '@/api/'
import { mapGetters } from 'vuex';
import moment from "moment-timezone";
import Vue from 'vue'
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
    data() {
        return {
            loading: false,
            fileList : [],
            fileData: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            percentageLoading:0,
            dataOK:true,
            selectedOrganization: null
        }
    }, 
    created () {
          this.$store.dispatch('organization/list', {max: 1000, offset: 0}, {
          root: true
      });
  },
    computed: {
      ...mapGetters({
        // 取得資料夾列表
        organizations: 'organization/organizations',
        getOrganizationById: 'organization/getOrganizationById',
      })
  },
    methods: {
      cellStyle(params) { //{row, column, rowIndex, columnIndex}
        if(params.columnIndex == 0 ) {
          if (params.row.status === '成功') {
            return 'color:#67C23A;'
          } else {
            return 'color:#F56C6C;'
          }
        } else {
          return '';
        }
      },
      upload() {
        for(const account of this.fileData) {
          if (account.status != null) {
            continue;
          }
          if (account.dataCorrect == 'NG') {
            continue;
          }
          const data = { ...account };
          data.organization = account.organization.id;
          this.$store.dispatch('account/add', data, {
          root: true
          }).then(res=>{
            account.status="成功";
          }).catch(error=>{
            account.status=error.response.data.message
          })
        }
      },
      dateFormatter(row, column, cellValue){
        if(cellValue){
          const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

          return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
          //return moment(cellValue).format('YYYY-MM-DD');
        }else{
          return cellValue;
        }

      },
        handleChange(file, fileList){
          this.fileData = [];
          this.total = 0;
            this.fileTemp = file.raw;
            if(this.fileTemp){
                if((this.fileTemp.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
                    || (this.fileTemp.type == 'application/vnd.ms-excel')){
                    this.importfxx(this.fileTemp);
                } else {
                    this.$message({
                        type:'warning',
                        message:'上傳檔案格式錯誤,請重新上傳!'
                    })
              this.fileTemp = null;
                }
            } else {
                this.$message({
                    type:'warning',
                    message:'Please upload the attachment!'
                })
            }
        },

        handleExceed(){
            this.$message({
                type:'warning',
                message:'超過上傳檔案數量限制!'
            })
            return;
        },
        findGroup(organization, groupNamesStr) {
          const groupNames = groupNamesStr.split(",").map(n=>n.trim());
          return organization.groups.filter(g=>{
            return groupNames.indexOf(g.name) >= 0;
          })
        },
        handleRemove(file,fileList){
            this.fileTemp = null;
        },
        importfxx(obj) {

            let _this = this;
            // Retrieving file data through DOM

            this.file = event.currentTarget.files[0];

            var rABS = false; //Read the file as a binary string
            var f = this.file;

            var reader = new FileReader();
            // if (!FileReader.prototype.readAsBinaryString) {
            FileReader.prototype.readAsBinaryString = function(f) {
                var binary = "";
                var rABS = false; //Read the file as a binary string
                var pt = this;
                var wb; //Read completed data
                var outdata;
                var reader = new FileReader();
                reader.onload = function(e) {

                    var bytes = new Uint8Array(reader.result);
                    var length = bytes.byteLength;
                    for (var i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    //If not introduced in main.js, you need to introduce it here to parse excel
                    var XLSX = require("xlsx");
                    if (rABS) {
                        wb = XLSX.read(btoa(fixdata(binary)), {
                        //Manual conversion
                        type: "base64"
                        });
                    } else {
                        wb = XLSX.read(binary, {
                        type: "binary"
                        });
                    }
                    outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); 
                    //outdata is read data (without header rows or headers, the header will be the subscript of the object)
                    //Data can be processed here.
                    const label_name = '名稱';
                    const label_no = '人員編號';
                    const label_class_name= '班級名稱';
                    const label_start_date = '生效日期';
                    const label_end_date = '失效日期';
                    const label_moonlight2_serial_number = '月光機序號';
                    const label_is_link_luciditv_app = '綁定澈見幸福App';
                    const label_phone_area ='註冊電話區碼';
                    const label_phone_number = '註冊電話號碼';
                    const label_groups = '群組';
                    const label_memo = '備註';
  
                    
                    _this.$store.dispatch('organization/getWithGroups', _this.selectedOrganization, {
                        root: true
                    }).then(res=>{
                      const organization = res.data;
                      outdata.forEach(v => {

                        let obj = {}
                        obj.name = `${v[label_name]}`.trim()
                        obj.no = `${v[label_no]}`.trim()
                        obj.class_name = `${v[label_class_name]}`.trim()
                        obj.start_date = moment(`${v[label_start_date]}`.trim()).unix()*1000
                        obj.end_date = moment(`${v[label_end_date]}`.trim()).unix()*1000
                        obj.moonlight2_serial_number = `${v[label_moonlight2_serial_number]}`.trim()
                        obj.is_link_luciditv_app = `${v[label_is_link_luciditv_app]}`.trim().toLowerCase() === 'yes'
                        obj.phone_area = `${v[label_phone_area]}`.trim()
                        obj.phone_number = `${v[label_phone_number]}`.trim()
                        obj.groups =  _this.findGroup(organization, `${v[label_groups]}`.trim())
                        obj.memo = `${v[label_memo]}`.trim()
                        obj.organization = organization;
                        obj.status = null
                        _this.total = _this.total +1;
                        obj.dataCorrect = 'OK'

                        if(v[label_name] === undefined){
                          obj.name = '未填寫';
                          obj.dataCorrect = 'NG';
                          obj.status = '名稱未填寫'
                          _this.dataOK = false;
                        }
                        if(v[label_no] === undefined){
                          obj.no = '';
                        }
                        if(v[label_class_name] === undefined){
                          obj.class_name = '';
                        }
                        if(v[label_start_date] === undefined){
                          obj.start_date = moment().unix()*1000;
                        }
                        if(v[label_end_date] === undefined){
                          obj.end_date = 4102358400000;
                        }
                        if(v[label_moonlight2_serial_number] === undefined){
                          obj.moonlight2_serial_number = null;
                        }
                        if(v[label_is_link_luciditv_app] === undefined){
                          obj.is_link_luciditv_app = 'No';
                        }
                        if(v[label_phone_area] === undefined){
                          obj.phone_area = null;
                        }
                        if(v[label_phone_number] === undefined){
                          obj.phone_number = null;
                        }
                        if(v[label_groups] === undefined){
                          obj.groups = [];
                        }
                        if(v[label_memo] === undefined){
                          obj.memo = '';
                        }
                        if(v[label_memo] === undefined){
                          obj.memo = '';
                        }
                        if(!obj.organization) {
                          obj.dataCorrect = 'NG';
                          _this.dataOK = false;
                        }
                        _this.fileData.push(obj);
                    });
                    })
                    
                };
                reader.readAsArrayBuffer(f);
            };
            if (rABS) {
                reader.readAsArrayBuffer(f);
            } else {
                reader.readAsBinaryString(f);
            }
        // }
        }
    }
}
</script>

<style>

</style>